import * as React from 'react'

import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Paper,
  Stack,
  useTheme,
} from '@mui/material'

import { Body, SectionWrapper, SeoHeaders, Subtitle, Title } from 'shared/components'

import faqData from '../data/faq'
import Layout from '../layouts/landing'

interface QuestionWrapperProps {
  children: NonNullable<React.ReactNode>
}

const QuestionWrapper = ({ children }: QuestionWrapperProps) => (
  <Accordion
    disableGutters
    elevation={0}
    sx={(theme) => ({
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '&:before': {
        display: 'none',
      },
    })}
  >
    {children}
  </Accordion>
)

interface QuestionTitleProps {
  section: string
  index: number
  children: React.ReactNode
}

const QuestionTitle = ({ section, index, children }: QuestionTitleProps) => {
  const palette = useTheme().palette

  return (
    <AccordionSummary
      expandIcon={<ExpandMore style={{ color: palette.common.white }} />}
      aria-controls={`panel-${section}-${index}-content`}
      id={`panel-${section}-${index}-header`}
      sx={{
        color: palette.common.white,
        bgcolor: palette.primary.dark,
      }}
    >
      <Body fontWeight='medium'>
        {children}
      </Body>
    </AccordionSummary>
  )
}

interface QuestionBodyProps {
  children: React.ReactNode
}

const QuestionBody = ({ children }: QuestionBodyProps) => (
  <AccordionDetails sx={{ p: 0 }}>
    <Paper
      elevation={12}
      sx={{ p: 2, borderRadius: 0, boxShadow: 'none' }}
    >
      <Body>
        {children}
      </Body>
    </Paper>
  </AccordionDetails>
)

const FaqPage = () => (
  <Layout>
    <SeoHeaders
      title='Preguntas Frecuentes'
      description={(
        'Respondemos tus dudas. Revisa las preguntas frecuentes respecto a tus'
        + ' inversiones en cardano, bitcoin, ethereum y polkadot.'
      )}
      schemaMarkup={{
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        'mainEntity': faqData
          .map((faqGroup) => faqGroup.questions)
          .flat()
          .map((question) => ({
            '@type': 'Question',
            'name': question.title.toString(),
            'acceptedAnswer': {
              '@type': 'Answer',
              'text': question.body?.toString(),
            },
          })),
      }}
    />
    <Container maxWidth='md'>
      <SectionWrapper>
        <Title
          mainTitle
          textAlign='center'
        >
          Preguntas Frecuentes
        </Title>
        {faqData.map((faqGroup, i) => (
          <Stack key={i}>
            <Subtitle
              id={faqGroup.section}
              mainTitle
              textAlign='center'
              pb={2}
            >
              {faqGroup.title}
            </Subtitle>
            <Paper
              elevation={12}
              sx={{ overflow: 'hidden' }}
            >
              {faqGroup.questions.map((question, j) => (
                <QuestionWrapper key={j}>
                  <QuestionTitle
                    section={faqGroup.section}
                    index={j}
                  >
                    {question.title}
                  </QuestionTitle>
                  <QuestionBody>
                    {question.body}
                  </QuestionBody>
                </QuestionWrapper>
              ))}
            </Paper>
          </Stack>
        ))}
      </SectionWrapper>
    </Container>
  </Layout>
)

export default FaqPage
